/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import parse from 'html-react-parser';
import { useMsal } from '@azure/msal-react';

import { Samples } from './Samples';
import { fetchResults, parseContent } from './function';

import './styles.scss';

export const Energy: React.FC = () => {
  const [report, setReport] = useState<{ name: string, answer: string, isOpen: boolean }[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [disable, setDisable] = useState(false);
  const [sampleClick, setSampleClick] = useState(false);
  const [selectedOption, setSelectedOption] = useState('gpt-3.5-turbo');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { instance } = useMsal();


  // eslint-disable-next-line no-constant-condition
  if (window.location.href.indexOf('chatsustainableenergy') > -1) {
    return <h1 className='my-5 w-100 text-center'>Coming soon...</h1>;
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!disable) {
      setInputValue(e.target.value);
      setSampleClick(false);
    }
  };

  const onSubmit = async () => {
    setDisable(true);
    setReport([]);

    if (selectedOption === 'gpt-4' && !instance.getActiveAccount()) {
      const errorText = 'You need to log in to access the GPT-4 feature.';
      setReport([{ name: 'Error', answer: errorText, isOpen: true }]);
      setDisable(false);
      return;
    }

    if (inputValue.length === 0) {
      const errorText = 'Enter the question field';
      setReport([{ name: 'Error', answer: errorText, isOpen: true }]);
      setInputValue('');
      setDisable(false);
      return;
    }

    try {
      const fetchedReport = await fetchResults(inputValue, selectedOption);
      setReport((prev) => [...prev, ...fetchedReport]);
    } catch (error) {
      console.error(error);
    }

    setDisable(false);
  };

  const toggleOpen = (index: number) => {
    const theReport = report[index];
    theReport.isOpen = !theReport.isOpen;
    setReport((prev) => {
      const newReport = [...prev];
      newReport[index] = theReport;
      return newReport;
    });
  };

  const onEnterPress = (e: any) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      onSubmit();
    }
  };

  const sampleHandler = (text: string) => {
    setInputValue(text);
    setSampleClick(true);
  };

  useEffect(() => {
    if (sampleClick) {
      onSubmit();
    }
  }, [sampleClick]);

  return (
    <div className="chat-window rounded-0">
      <div className="w-100 p-3">
        <h1 className="text-center">Ask from trusted resources</h1>
        <h6 className="mb-4 text-center text-secondary">{ '(UNECE, WMO, IPCC, IEA, IAEA, OSCE)' }</h6>
        <div className="row text-center center-align align-center">
          <div className="col-md-12">
            <div className={ `textarea-wrapper ${inputValue.length === 0 ? 'error' : ''}` }>
              <textarea
                value={ inputValue }
                onChange={ handleChange }
                onKeyDown={ onEnterPress }
                placeholder="Ask your question..."
                className="form-control"
                rows={ 4 }
              />
              <Button
                variant="primary"
                onClick={ disable ? () => {} : onSubmit }
                disabled={ disable }
                className="svg-button"
              >
                { disable ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </>
                ) : (
                  <i className="bi bi-send" />
                ) }
              </Button>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type="radio"
                id="gpt-3.5-turbo"
                name="gpt-option"
                value="gpt-3.5-turbo"
                checked={ selectedOption === 'gpt-3.5-turbo' }
                onChange={ () => setSelectedOption('gpt-3.5-turbo') }
              />
              <label
                className='form-check-label'
                htmlFor="gpt-3.5-turbo"
              >gpt-3.5-turbo
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type="radio"
                id="gpt-4"
                name="gpt-option"
                value="gpt-4"
                checked={ selectedOption === 'gpt-4' }
                onChange={ () => setSelectedOption('gpt-4') }
              />
              <label
                className='form-check-label'
                htmlFor="gpt-4"
              >gpt-4
              </label>
            </div>
            { report.length > 0 ?
              <h6 className="responses-header text-start mt-4">Responses:</h6>
              : !sampleClick && <Samples onClick={ sampleHandler } />
            }
            { disable && (
              <div className="text-center mt-3">
                <Spinner
                  animation="border"
                  role="status"
                  // variant="info"
                />
                <p className='text-body'>
                  {
                    selectedOption === 'gpt-3.5-turbo' ?
                      // eslint-disable-next-line max-len
                      'Please note that this is only a demo, it takes 20 seconds for gpt-3.5 engine to answer the questions.' :
                      // eslint-disable-next-line max-len
                      'Please note that this is only a demo, it takes 50 seconds for gpt-4 engine to answer the questions.'
                  }
                </p>
              </div>
            ) }
          </div>
        </div>
      </div>
      <div className="w-100 px-3">
        {
          report.map((item, index) => (
            !item.name ? null :
              <div
                key={ index.toString() + 'item' }
                className="answer-org"
              >
                <h3
                  className='answer-title'
                  onClick={ () => toggleOpen(index) }
                >
                  { item.isOpen ? (
                    <i className="bi bi-caret-up-fill" />
                  ) : (
                    <i className="bi bi-caret-down-fill" />
                  ) }

                  { item.name }
                </h3>
                { item.isOpen &&
              <div className='answer-content'>{ parse(parseContent(item.answer)) }</div>
                }
              </div>
          )) }
      </div>
    </div>
  );
};