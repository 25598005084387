import { API_URI } from './consts';
import { OrgData } from './types';

export const parseContent = (content: string) => {
  const regex = /(http[s]?:\/\/[^\s]+);/g;
  const parsedContent = content.replace(regex, (match) =>
    `<a class='ms-1' href="${match.replace(';', '')}" target="_blank">[ref]</a>`);
  return parsedContent.replace(/\n/g, '<br>');
};


export const fetchResults = async (inputValue: string, selectedOption: string): Promise<OrgData[]> => {
  const userQuestionTypes = [0]; //, 1, 4, 5, 6, 7 // 2,3
  const report: OrgData[] = [];

  const fetchResultsPromises = userQuestionTypes.map(async (userQuestionType, index) => {
    try {
      const formdata = new FormData();
      formdata.append('user_question_energy', inputValue);
      formdata.append('user_question_type', String(userQuestionType));
      formdata.append('user_gpt_core', selectedOption);

      const requestOptions: RequestInit = {
        method: 'POST',
        body: formdata,
      };

      // Add a 10-second wait before each fetch request for dev
      await new Promise((resolve) => setTimeout(resolve, index * 0));

      const response = await fetch(API_URI, requestOptions);
      const data: OrgData[] = await response.json();
      data.forEach((orgData) => {
        report.push({ name: orgData.name, answer: orgData.answer, isOpen: false });
      });
    } catch (error) {
      console.error(error);
    }
  });

  try {
    await Promise.all(fetchResultsPromises);
  } catch (error) {
    console.error(error);
  }

  return report;
};