
import React from 'react';
import { ISample } from './types';


export const Sample: ISample = ({
  text,
  onClick,
}) => (
  <button
    type="button"
    className="btn btn-outline-secondary m-2"
    onClick={ onClick }
  >
    { text }
  </button>
);

