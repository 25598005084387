import React from 'react';
import { Sample } from './Sample';
import { ISamples } from './types';

export const Samples: ISamples = ({
  onClick,
}) => (
  <div className='sample-q mt-4'>
    <h6>Try asking</h6>

    <div className='d-flex flex-wrap justify-content-center'>
      <Sample
        text="What is the resilient energy system?"
        onClick={ () => onClick('What is the resilient energy system?') }
      />
      <Sample
        text="What are the main pillars of resilient energy systems?"
        onClick={ () => onClick('What are the main pillars of resilient energy systems?') }
      />
      <Sample
        text="Could we reach net zero targets without nuclear energy?"
        onClick={ () => onClick('Could we reach net zero targets without nuclear energy?') }
      />
    </div>
  </div>
);
