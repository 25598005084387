import React from 'react';

export const Disclaimer: React.FC = () => (
  <div className="disclaimer chat-window">
    <h1>Disclaimer for ChatEnergy.app</h1>
    <p>ChatEnergy.app is a prototype software application created solely for demonstration purposes.
       Please be informed of the following crucial points before proceeding:
    </p>

    <ol>
      <li>
        <strong>Demo Status: </strong>
         ChatEnergy.app is available exclusively as a demo version to exhibit its potential features and capabilities.
         It is not a fully-fledged or finalized product.
      </li>
      <li>
        <strong>Restricted Data Training: </strong>
         The development of ChatEnergy.app involved training on a confined dataset of 32 PDF files.
         Consequently, its knowledge base and response accuracy are
          limited to the information contained within this dataset.
      </li>
      <li>
        <strong>Not a Substitute for Human Judgment: </strong>
         ChatEnergy.app is not designed to replace human decision-making or professional consultation.
         Any decisions stemming from its responses should be made with discretion, and at your own risk.
      </li>
      <li>
        <strong>Potential Incomplete Responses: </strong>
         Due to its demo nature, ChatEnergy.app might not provide
          exhaustive answers or may leave certain queries unanswered.
         It lacks the extensive information access that a fully developed, specialized tool would possess.
      </li>
    </ol>

    <p>
      By engaging with ChatEnergy.app, you acknowledge and accept the aforementioned limitations.
       It is imperative to utilize this tool responsibly, aligning with its demonstration purpose,
        and not solely depend on ChatEnergy.app for critical decision-making or actions.
    </p>
  </div>
);
