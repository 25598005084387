import React, { useEffect } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

import { loginRequest } from 'authConfig';
import { Energy } from 'components/Energy';
import { Disclaimer } from 'components/Disclaimer';

export const Naviation: React.FC = () => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { instance, inProgress } = useMsal();

  const handleLoginRedirect = () => {
    // eslint-disable-next-line no-console
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  useEffect(() => {
    if (instance) {
      const activeAccount = instance.getActiveAccount();
      // eslint-disable-next-line no-console
      console.log(activeAccount);
      if (activeAccount && location.href.includes('auth')) {
        location.href = '/';
      } else if (!activeAccount && location.href.includes('logout')) {
        location.href = '/';
      }
    }
  }, [instance, inProgress, location.href]);


  return (
    <div className='app-container'>
      <nav className='w-100 px-3 py-1 d-flex justify-content-between'>
        <ul className='d-flex gap-3 list-unstyled my-2'>
          <li>
            <Link
              className='text-decoration-none'
              to="/"
            >Home
            </Link>
          </li>
          <li>
            <Link
              className='text-decoration-none'
              to="/disclaimer"
            >disclaimer
            </Link>
          </li>
        </ul>
        <ul className='d-flex gap-3 list-unstyled my-2'>
          <AuthenticatedTemplate>
            <li>
              <Link
                className='text-decoration-none'
                to=''
                onClick={ handleLogoutRedirect }
              >
              Log out
              </Link>
            </li>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <li>
              <Link
                className='text-decoration-none'
                to=''
                onClick={ handleLoginRedirect }
              >
              Log in / Sign up
              </Link>
            </li>
          </UnauthenticatedTemplate>
        </ul>
      </nav>

      <Routes>
        <Route
          path="/"
          element={
            <Energy />
          }
        />
        <Route
          path="/disclaimer"
          element={
            <Disclaimer />
          }
        />
      </Routes>
    </div>);
};