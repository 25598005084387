import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MsalProvider } from '@azure/msal-react';

import { Naviation } from './components/Navigation/Navigation';

import './App.scss';

const App: React.FC<{ msalInstance: any }> = ({ msalInstance }) => (
  <MsalProvider instance={ msalInstance }>
    <Naviation />
  </MsalProvider>
);

export default App;
